/* Defines Screen-Specific size variables and overwrites */
@use "../mixins/rigged-background" as *;

#main-frame {
	display: flex;

	#actual-content {
		display: flex;
		height: 100vh;
		flex-grow: 1;
		flex-direction: column;

		.page-header {
			@include rigged-background();
		}

		.page-header,
		#page-header-wrapper {
			display: flex;
			flex-direction: column;
		}
	}
}
